@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep {
  .v-icon {
    color: $black;
    font-weight: 500;
  }

  .v-label {
    color: $black;
    font-size: 0.8em;
    font-weight: 500;
  }
}
