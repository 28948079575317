@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep {
  small,
  .v-label,
  .v-input,
  .v-input .v-input__icon--append > .v-icon,
  .v-menu__content {
    font-size: 12px;
    max-width: revert;
  }

  .v-input {
    font-weight: 500;
  }

  .v-slide-group__content {
    padding-top: 0;
  }
}
.sheet__header {
  position: relative;
  margin-bottom: 14px;
}
.sheet__close-btn {
  position: absolute;
  top: -7px;
  right: -20px;
}
.sheet__regions,
.sheet__categories {
  margin-top: -4px;
}

.sheet__sort-by {
  margin-top: -4px;
}

::v-deep .v-list {
  @include media('xs-only') {
    padding-top: 0;
    padding-bottom: 0;
  }
}
