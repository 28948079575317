@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep {
  small,
  .v-label,
  .v-input,
  .v-input .v-input__icon--append > .v-icon,
  .v-menu__content {
    max-width: revert;
  }

  .v-input {
    font-weight: 500;
  }
}
.mob-store-switcher {
  display: flex;
  font-size: 15px;
  font-weight: bold;
  padding-top: 20px;

  .v-icon {
    margin-left: 0;
  }
}
.sheet {
  padding-bottom: 20px;
}
.sheet__header {
  position: relative;
  margin-bottom: 14px;
}
.sheet__heading {
  color: black;
}
.sheet__close-btn {
  position: absolute;
  top: -7px;
  right: -20px;
}
.sheet__regions,
.sheet__stores {
  margin-top: -4px;
}

::v-deep .v-list {
  @include media('xs-only') {
    padding-top: 0;
    padding-bottom: 0;
  }
}
