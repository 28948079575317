@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .mso-new-asset__alert {
  font-size: 0.8rem;
  .v-icon.v-alert__icon {
    font-size: 1rem;
    margin-right: 0.5em !important;
  }
}

::v-deep {
  .group-filters {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    .v-btn {
      background-color: transparent;
      &::before {
        content: none;
      }
    }
  }
}

.create-nav {
  padding-top: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.create-nav__cancel {
  text-transform: none;
}
.create-nav__filter {
  min-width: 83px;
}

.create-asset-success {
  margin-bottom: -20px;
}

.assets-container {
  position: relative;
  min-height: 200px;
}
